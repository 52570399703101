.hero {
  //background-color: #2FEBE0;
  width: 100vw;
  height: 35vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  .text-left {
    h1 {
      padding-right: 5vw;
    }
    h2 {
      padding-right: 5vw;
    }
    #request-cta {
      margin-right: 5vw;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    h1 {
      padding-left: 5vw;
      font-size: 3rem;
      color: $font-dark;
      .splash {
        font-size: 3rem;
      }
    }
    h2 {
      padding-left: 5vw;
      font-size: 1.5rem;
      color: $font-dark;
      margin-top: 1%;
      font-weight: normal;
      opacity: .8;
      .splash {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
    #request-cta {
      text-decoration: none;
      font-size: 1.2rem;
      color: $font-main;
      background-color: $main-splash;
      padding: .5vh 1vw;
      border-radius: 5px;
      margin-left: 5vw;
      margin-top: 3vh;
      width: max-content;
      &:hover {
        opacity: .8;
      }
    }}
}
.graphic-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#drag {

  z-index: 100;
}
.gooey{
  background-image: linear-gradient(120deg, #0a80ef 0%, #5905ea 100%);
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  width: 20vw; height: 19vw;
  animation: morph 30s linear infinite;
  transform-style: preserve-3d;
  outline: 1px solid transparent;
  will-change: border-radius;
  //float: right;
  //margin-left: auto;
  opacity: .5;
  //margin-left: 17vw;
  cursor: pointer;
}
.gooey:before,
.gooey:after{
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0; top: 0;
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  box-shadow: 5px 5px 89px rgba(0, 102, 255, 0.21);
  will-change: border-radius, transform, opacity;
  animation-delay: 200ms;
  background-image: linear-gradient(120deg, $main-splash 0%, rgba(0, 109, 252, 0.89) 100%);
}

.gooey:before{
  animation: morph 30s linear infinite;
  opacity: .21;
  animation-duration: 5s;
}

.gooey:after{
  animation: morph 10s linear infinite;
  animation-delay: 400ms;
  opacity: .89;
  line-height: 120px;
  text-indent: -21px;
}

@keyframes morph{
  0%,100%{
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  34%{
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform:  translate3d(0,5px,0) rotateZ(0.01deg);
  }
  50%{
    opacity: .89;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  67%{
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
    transform: translate3d(0,-3px,0) rotateZ(0.01deg);
  }
}

@keyframes fadeIn {
  100% {
    transform: scale(1.03);
    opacity: 0;
  }
}