.notification-portal {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 30%;
  bottom: 4vh;
  left: 2vw;
  .notification {
    display: flex;
    border-left: solid 3px;
    background-color: $main-mid;
    .notification-content {
      margin: 2% 2%;
    }
  }
}