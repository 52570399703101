.page-block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .error {
    span {
      font-size: 2rem;
    }
    .error-stack {
      display: flex;
      flex-direction: column;
      align-items: center;
      #under-construction {
        font-size: 1.25rem;
        opacity: .5;
        margin-top: 3%;
      }
    }
  }
}
.no-mobile {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}