.header {
  display: flex;
  padding: 4vh 2vw;
  align-items: center;
  //background-color: saddlebrown;
  .nv-logo {
    float: left;
    cursor: pointer;
  }
  #auth-actions {
    float: right;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    z-index: 12;
    .auth-button {
      display: flex;
      align-items: center;
      color: $font-dark !important;
      text-decoration: none;
      font-size: 1.25rem;
      &:hover {
        color: $main-splash !important;
      }
    }
    .auth-active {
      border-bottom: solid 2px $main-splash;
    }
    #login-button {
      background-color: $main-splash;
      height: max-content;
      padding: .25vh .5vw;
      border-radius: 5px;
      color: $font-main !important;
      &:hover {
        opacity: .8;
      }
    }
  }
}